import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import MyCarousel from '../components/Carousel';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
import { Seo } from "../components/seo";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const IndexPage = () => (
  <Layout>
    <Seo />
    <section id="Inicio">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>

       {/*
        <ul className="actions special">
          <li>
            <Scroll type="id" element="SobreNos">
              <a href="/#" className="button secondary">
                Explorar
              </a>
            </Scroll>
          </li>
        </ul>
        */ }

      </div>
      <Scroll type="id" element="SobreNos">
        <a href="#SobreNos" className="more">
          Ler Mais
        </a>
      </Scroll>
    </section>

    <section id="SobreNos" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Sobre Nós</h2>
          <p>
            A V&V CONFRA LDA é uma empresa que atua no mercado de construção, remodelação e reabilitação de imóveis. 
            <br />
            A empresa é liderada pelo Victor Surdu e Ion Munteanu, ambos com vasta experiência em area de construção e conta com uma equipa interna pluridisciplinar nas várias atividades do setor.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-clock major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-tasks major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-city major style1">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="AtividadesExercidas" className="wrapper alt style2 special">
      <header className="major wrapperSmall">
        <h2>Atividades Exercidas</h2>
      </header>
    
      <section className="spotlight">
        <div className="image a">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h3>Demolição</h3>
          <p>
            A demolição, para ser realizada, deve seguir normas técnicas e de segurança a fim de evitar ocorrência de fatos indesejados, 
            como prejuízos ao patrimônio, à saúde ou à vida das pessoas que nela trabalham ou venham a ter contato.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image b">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h3>
            Preparação dos locais de construção
          </h3>
          <p>
            Compreende as actividades de: terraplenagens (desaterros, aterros, escavações, nivelamento de terrenos, etc.); 
            limpeza dos locais de construção; drenagem e outras preparações dos locais de construção 
            (inclui as actividades de preparação dos terrenos associados às actividades mineiras e drenagem de terrenos agrícolas e florestais) 
            realizadas por empresas especializadas nestas actividades.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image c">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h3>
            Trabalhos de carpintaria e caixilharia
          </h3>
          <p>
            Compreende as actividades de colocação de armários, roupeiros, portas, 
            janelas, estores e a colocação de trabalhos similares em madeira e em outros materiais 
            (inclui os resistentes aos fogo). Inclui trabalhos de carpintaria executados e destinados 
            à sua aplicação na obra (fixação de cofragens em madeira, tectos falsos, divisórias, etc.).
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image b">
          <MyCarousel/>
        </div>
        <div className="content">
          <h3>
            Nossos trabalhos mais recentes
          </h3>
          <p>
            Esse trabalho foi efetuado no Porto Salvo, 
            foi exercido da nossa parte varios tipos de trabalho, a maior parte foi a betonagem de varios predios.
            Muito importante em esse tipo de trabalho e o ritmo de aplicação que deve ser ajustado para que medeie o menor
            tempo possível entre a colocação do betão de autobetoneiras consecutivas evitando assim a formação de juntas frias.
          </p>
        </div>
      </section>
    </section>

    <section id="NossosServiços" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Nossos Serviços</h2>
          <p>
            Podemos ajudá-lo em cada fase do seu projecto.
            <br />
            Fazemos questão de encontrar a equipa e os materiais mais adequados a cada projeto, 
            <br />
            e acabar tudo dentro do prazo estabelecido.
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-snowplow">
            <h4>Demolições e Desmantelamentos</h4>
            <p>
              Esse e o melhor começo para sua obra, compreende as actividades de demolição de pequenos edifícios e de outras construções.
            </p>
          </li>
          <li className="icon solid fa-industry">
            <h4>Preparação de construção</h4>
            <p>
              Compreende as actividades de: terraplenagens (desaterros, aterros, escavações, nivelamento de terrenos, etc.); 
              limpeza dos locais de construção;
            </p>
          </li>
          <li className="icon solid fa-ruler-horizontal">
            <h4>Construção de fundações</h4>
            <p>
            Assegurar a ligação entre qualquer estrutura e o terreno; Transferir as cargas das estruturas, para o terreno; Elementos fundamentais na estabilidade das estruturas.
            </p>
          </li>
          <li className="icon solid fa-paint-roller">
            <h4>Acabamento de edifícios</h4>
            <p>
              Colocação de vidros, estucagem, pintura e decoração, revestimento de pavimentos e paredes, lareiras, etc.
            </p>
          </li>
          <li className="icon solid fa-vector-square">
            <h4>Preparação de armação de ferro para construção</h4>
            <p>
              Utilização como estrutura de um local normalmente construções em andamento, 
              que ainda não receberam seus toques finais, mas ainda pedem por maior estrutura e resistência.
            </p>
          </li>
          <li className="icon solid fa-plug">
            <h4>Instalação elétrica e de canalizações</h4>
            <p>
              Trabalhamos em todas as regiões da grande Lisboa. Melhor relação qualidade / preço.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="Contactos" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Peça o seu orçamento grátis</h2>
          <p>
            Temos soluções para todos tipos de clientes.
            <br/>
            Se ainda tem dúvidas, pode:
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/#Inicio" className="button fit secondary xs">
              Voltar ao Início
            </a>
          </li>
          <li>
            <a href="/#NossosServiços" className="button fit xs">
              Rever os Serviços
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
