import React from "react"
import { Carousel } from 'react-responsive-carousel';

import pic1 from '../assets/images/trab01.jpg';
import pic2 from '../assets/images/trab02.jpg';
import pic3 from '../assets/images/trab03.jpg';
import pic4 from '../assets/images/trab04.jpg';
import pic5 from '../assets/images/trab05.jpg';
import pic6 from '../assets/images/trab06.jpg';
import pic7 from '../assets/images/trab07.jpg';
import pic8 from '../assets/images/trab08.jpg';
import pic9 from '../assets/images/trab09.jpg';

export default () => (
    <Carousel 
      autoPlay
      width="100%"
      infiniteLoop
      centerMode={true}
    >
        <div>
            <img alt="" src={pic1} />
            <p className="legend">Planeamento da betonagem</p>
        </div>
        <div>
            <img alt="" src={pic2} />
            <p className="legend">Início da betonagem</p>
        </div>
        <div>
            <img alt="" src={pic3} />
            <p className="legend">Início da betonagem</p>
        </div>
        <div>
            <img alt="" src={pic4} />
            <p className="legend">Betonagem</p>
        </div>
        <div>
            <img alt="" src={pic5} />
            <p className="legend">Acabamento</p>
        </div>
        <div>
            <img alt="" src={pic6} />
            <p className="legend">Betonagem</p>
        </div>
        <div>
            <img alt="" src={pic7} />
            <p className="legend">Betonagem</p>
        </div>
        <div>
            <img alt="" src={pic8} />
            <p className="legend">Betonagem</p>
        </div>
        <div>
            <img alt="" src={pic9} />
            <p className="legend">Acabamento</p>
        </div>
    </Carousel>
);